export const Environment = {
  LIMITE_DE_LINHAS: 10,
  URL_BACKEND:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://gvquest-dev-wa.azurewebsites.net/api/v1`
      : window.location.host.includes('-hml')
      ? `https://gvquest-hml-wa.azurewebsites.net/api/v1`
      : `https://gvquest-prod-wa.azurewebsites.net/api/v1`,
  URL_API:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/quest/api/v1`
      : window.location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/quest/api/v1`
      : `https://api.gvdasa.com.br/quest/api/v1`,
  API_GATEWAY:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://api-dev.gvdasa.com.br/`
      : window.location.host.includes('-hml')
      ? `https://api-hml.gvdasa.com.br/`
      : `https://api.gvdasa.com.br/`,
  URL_ORIGIN_DEBUG: window.location.host.includes('localhost')
    ? // ? 'https://a87-dev.quest.gvdasa.com.br/'
      'https://dgt01-dev.quest.gvdasa.com.br/'
    : '',
  URL_SERVICO_RELATORIOS:
    window.location.host.includes('localhost') ||
    window.location.host.includes('-dev')
      ? `https://relatorios-dev.gvdasa.com.br/`
      : window.location.host.includes('-hml')
      ? `https://relatorios-hml.gvdasa.com.br/`
      : `https://relatorios.gvdasa.com.br/`,
  URL_WC_FEEDABACK:
    process.env.REACT_APP_ENV === 'prod'
      ? 'https://wc-feedbacks.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'hml'
      ? 'https://wc-feedbacks-hml.gvdasa.com.br'
      : process.env.REACT_APP_ENV === 'dev'
      ? 'https://wc-feedbacks-dev.gvdasa.com.br'
      : 'https://wc-feedbacks-dev.gvdasa.com.br',
};
