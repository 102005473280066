import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {
  Box,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Theme,
} from '@mui/material';
import {Loading} from './Loading';

import {useTitle} from 'shared/hooks';
import {
  CentralConvitesService,
  IConvite,
} from 'shared/services/api/central-convites/CentralConvitesService';
import {feedback} from 'shared/services';
import {
  BarChartRounded,
  LaunchRounded,
  LinkRounded,
  ReplayRounded,
  Search,
} from '@mui/icons-material';
import {format} from 'date-fns';
import {NotFoundText} from 'shared/components';
import {useNavigate} from 'react-router-dom';
import {Environment} from 'shared/Env';
import {StatusConvite} from './StatusConvite';
import {HistoricoModal} from './HistoricoModal';
import {makeStyles} from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    '&.MuiToggleButton-root.Mui-selected': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));

type TStatusFilter = 'todos' | 'ativos' | 'inativos';

export const CentralConvitesListagem = () => {
  const cssClasses = useStyles();
  const {setTitle} = useTitle();
  const navigate = useNavigate();

  setTitle('Central de convites');

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingRefresh, setIsLoadingRefresh] = useState(false); //Quando tivermos a api, testar o tempo de resposta e tentar adicionar um mini loading ao lado do groupbutton
  const [search, setSearch] = useState('');
  const [todosConvites, setTodosConvites] = useState<IConvite[]>();
  const [convitesFiltrados, setConvitesFiltados] = useState<IConvite[]>();
  const [statusFilter, setStatusFilter] = useState<TStatusFilter>('todos');
  const [page, setPage] = useState(1);

  const convitesAtivos = useMemo(() => {
    return (
      convitesFiltrados &&
      convitesFiltrados.filter((convite) => convite.QuestionarioAtivo)
    );
  }, [convitesFiltrados]);

  const convitesInativos = useMemo(() => {
    return (
      convitesFiltrados &&
      convitesFiltrados.filter((convite) => !convite.QuestionarioAtivo)
    );
  }, [convitesFiltrados]);

  const convites = useMemo(() => {
    if (statusFilter === 'ativos') {
      return convitesAtivos;
    } else if (statusFilter === 'inativos') {
      return convitesInativos;
    } else {
      return convitesFiltrados;
    }
  }, [convitesAtivos, convitesFiltrados, convitesInativos, statusFilter]);

  useEffect(() => {
    refreshRequest();
    const intervalId = setInterval(() => {
      refreshRequest();
    }, 30000); // 30 segundos

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const refreshRequest = () => {
    setIsLoadingRefresh(true);
    CentralConvitesService.getAll()
      .then((result) => {
        if (result.success) {
          setTodosConvites(result.data);
          setConvitesFiltados(result.data);
        } else {
          setTodosConvites([]);
          setConvitesFiltados([]);
          feedback(result.message || 'Erro ao carregar convites.', 'error');
        }
      })
      .finally(() => {
        setIsLoadingRefresh(false);
        setIsLoading(false);
      });
  };

  const handleSearch = (searchParameter: string) => {
    setSearch(searchParameter);

    const filteredInvites = convites?.filter(
      (convite) =>
        convite.NomeQuestionario.toLowerCase().includes(
          searchParameter.toLowerCase(),
        ) ||
        convite.Descricao.toLowerCase().includes(searchParameter.toLowerCase()),
    );

    setConvitesFiltados(filteredInvites);

    if (searchParameter === '') {
      setConvitesFiltados(todosConvites);
    }
  };

  const handleSetStatusFilter = (
    _: React.MouseEvent<HTMLElement>,
    statusSelected: TStatusFilter,
  ) => {
    setStatusFilter(statusSelected);
  };

  const handleCopyInviteLink = (link: string) => {
    navigator.clipboard.writeText(link).then(
      () => {
        feedback('Link copiado para a área de transferência', 'success');
      },
      () => {
        feedback(
          'Não foi possível copiar o link para a área de transferência',
          'error',
        );
      },
    );
  };

  const handlePageChange = useCallback(
    (event: React.ChangeEvent<unknown>, value: number) => {
      setPage(value);
    },
    [],
  );

  const handleRepeatProcess = () => {};

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <Grid item xs={6}>
            <Box
              display={'flex'}
              justifyContent={'flex-start'}
              alignItems={'center'}>
              <Box
                display={'flex'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                gap={1}>
                <FormControl variant="outlined" fullWidth>
                  <Paper>
                    <TextField
                      fullWidth
                      variant="outlined"
                      disabled={todosConvites && todosConvites.length <= 0}
                      placeholder="Pesquise nome e questionário"
                      value={search}
                      onChange={(e) => {
                        handleSearch(e.target.value);
                      }}
                      InputProps={{
                        style: {
                          height: 40,
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            {isLoading ? (
                              <CircularProgress size={18} />
                            ) : (
                              <Search color="disabled" />
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Paper>
                </FormControl>
                <Box marginRight={2}>
                  <Tooltip title={'Filtrar convites ativos e inativos.'}>
                    <ToggleButtonGroup
                      size="small"
                      value={statusFilter}
                      exclusive
                      onChange={handleSetStatusFilter}>
                      <ToggleButton
                        disabled={todosConvites && todosConvites.length <= 0}
                        color="primary"
                        value={'todos'}
                        className={cssClasses.toggleButton}>
                        Todos
                      </ToggleButton>
                      <ToggleButton
                        disabled={todosConvites && todosConvites.length <= 0}
                        color="primary"
                        value={'ativos'}
                        className={cssClasses.toggleButton}>
                        Ativos
                      </ToggleButton>
                      <ToggleButton
                        disabled={todosConvites && todosConvites.length <= 0}
                        color="primary"
                        value={'inativos'}
                        className={cssClasses.toggleButton}>
                        Inativos
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Tooltip>
                </Box>
              </Box>
              {isLoadingRefresh && (
                <Box display={'flex'} alignItems={'center'}>
                  <CircularProgress size={20} />
                </Box>
              )}
            </Box>
          </Grid>
          {convites && (
            <Grid item xs={12} marginBottom={1} marginTop={3}>
              <TableContainer component={Paper} elevation={3}>
                <Table cellPadding={2}>
                  <TableHead>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Nome
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Período
                      </TableCell>
                      <TableCell component="th" scope="row">
                        Questionário
                      </TableCell>
                      <TableCell component="th" scope="row" align="left">
                        Status
                      </TableCell>
                      <TableCell component="th" scope="row" align="right">
                        Ação
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {convites
                      .slice(
                        (page - 1) * Environment.LIMITE_DE_LINHAS,
                        (page - 1) * Environment.LIMITE_DE_LINHAS +
                          Environment.LIMITE_DE_LINHAS,
                      )
                      .map((convite) => (
                        <TableRow key={convite.Id}>
                          <TableCell component="td" scope="row">
                            {convite.Descricao}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            sx={{whiteSpace: 'nowrap'}}>{`${format(
                            new Date(convite.DataHoraInicio),
                            'dd/MM/yyyy',
                          )} à ${format(
                            new Date(convite.DataHoraFim),
                            'dd/MM/yyyy',
                          )}`}</TableCell>
                          <TableCell component="td" scope="row">
                            {convite.NomeQuestionario}
                          </TableCell>
                          <TableCell component="td" scope="row" align="left">
                            {<StatusConvite status={convite.Status} />}
                          </TableCell>
                          <TableCell
                            component="td"
                            scope="row"
                            align="right"
                            sx={{whiteSpace: 'nowrap'}}>
                            <Box>
                              <Tooltip
                                placement="top"
                                title="Acessar relatório">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/relatorios/detalhe/${convite.Id}`,
                                    )
                                  }>
                                  <BarChartRounded fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                title="Acessar questionário">
                                <IconButton
                                  onClick={() =>
                                    navigate(
                                      `/questionarios/detalhe/${convite.IdQuestionario}`,
                                    )
                                  }>
                                  <LaunchRounded fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                placement="top"
                                title=" Copiar link do convite">
                                <IconButton
                                  onClick={() =>
                                    handleCopyInviteLink(convite.LinkConvite)
                                  }>
                                  <LinkRounded fontSize="small" />
                                </IconButton>
                              </Tooltip>
                              <HistoricoModal id={convite.Id} />

                              <Tooltip
                                placement="top"
                                title="Reprocessar convite">
                                <IconButton
                                  onClick={handleRepeatProcess}
                                  disabled={
                                    !(
                                      convite.Status === 'ERRO_LIBERACAO' ||
                                      convite.Status ===
                                        'PUBLICO_ALVO_SEM_DESTINATARIOS'
                                    )
                                  }>
                                  <ReplayRounded fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    {convites && convites?.length <= 0 && (
                      <TableRow>
                        <TableCell colSpan={12}>
                          <NotFoundText />
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                {convites && convites?.length > 0 && (
                  <Box
                    marginTop={1}
                    marginBottom={1}
                    display={'flex'}
                    justifyContent={'end'}>
                    <Pagination
                      page={page}
                      count={Math.ceil(
                        convites.length / Environment.LIMITE_DE_LINHAS,
                      )}
                      onChange={handlePageChange}
                    />
                  </Box>
                )}
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
