import {Api} from 'shared/services/axios-config';
import {IResultOfAction} from 'shared/services/axios-config/interceptors';

import {IOptions} from 'shared/services/api/student-quiz/QuestionList';
import {TTypeOfQuestion} from 'shared/types/QuestionTypes';

export interface IPerson {
  nome: string;
  papel: string;
  matricula: string;
  descricaoCurso: string;
}

export interface IQuestion {
  idQuestao: string;
  idResposta: string;
  enunciado: string;
  descricao: string;
  permiteComentario: boolean;
  comentario: string;
  respondido: boolean;
  tipoQuestao: TTypeOfQuestion;
  ordem: number;
  obrigatoria: boolean;
  respostaTexto: string;
  opcoesRespostaLinha: IOptions[];
}

export interface IMultiplier {
  codigo: string;
  nome: string;
  professor: string;
  todasRespondidas: boolean;
  questoes: IQuestion[];
}

export interface ISection {
  idSecao: string;
  idPessoaConvite: string;
  ordem: string;
  nomeSecao: string;
  idQuestionario: string;
  multiplicadores: IMultiplier[];
}

export interface IIndividualAnswers {
  tituloQuestionario: string;
  descricao: string;
  bannerBase64?: string;
  logoBase64?: string;
  respondidoEm: string;
  statusPessoaConvite: string;
  pessoa: IPerson;
  respostaAnonima: boolean;
  secoes: ISection[];
}

export interface IParticipant extends IPerson {
  id: string;
}

const getById = async (
  idPessoaConvite: string,
): Promise<IResultOfAction<IIndividualAnswers>> => {
  try {
    const {data} = await Api.get(
      `/relatorios/respostasIndividuais/${idPessoaConvite}`,
    );

    const object: IIndividualAnswers = {
      tituloQuestionario:
        data.TituloQuestionario !== null ? '' : data.TituloQuestionario,
      descricao: data.Descricao !== null ? data.Descricao.toUpperCase() : '',
      bannerBase64:
        data.BannerBase64 !== null && data.BannerBase64 !== ''
          ? `data:image/png;base64,${data.BannerBase64}`
          : undefined,
      logoBase64:
        data.LogoBase64 !== null && data.LogoBase64 !== ''
          ? `data:image/png;base64,${data.LogoBase64}`
          : undefined,
      respondidoEm: data.RespondidoEm,
      statusPessoaConvite: data.StatusPessoaConvite,
      respostaAnonima: data.RespostasAnonimas,
      pessoa:
        data.Pessoa !== null
          ? {
              nome: data.Pessoa.Nome || '',
              papel: data.Pessoa.Papel.toUpperCase() || '',
              matricula: data.Pessoa.Matricula || '',
              descricaoCurso:
                data.Pessoa.DescricaoCurso !== null
                  ? data.Pessoa.DescricaoCurso.toUpperCase()
                  : '',
            }
          : {
              nome: '',
              papel: '',
              matricula: '',
              descricaoCurso: '',
            },
      secoes: data.Secoes.length
        ? data.Secoes.map(
            (secao: any): ISection => ({
              idSecao: secao.IdSecao,
              idPessoaConvite: secao.IdPessoaConvite,
              ordem: secao.Ordem,
              nomeSecao: secao.NomeSecao,
              idQuestionario: secao.IdQuestionario,
              multiplicadores: secao.Multiplicadores.map(
                (multiplicador: any): IMultiplier => ({
                  codigo: multiplicador.Codigo,
                  nome: multiplicador.Nome.toUpperCase(),
                  professor: multiplicador.Professor,
                  todasRespondidas: multiplicador.TodasRespondidas,
                  questoes: multiplicador.Questoes.map(
                    (questao: any): IQuestion => ({
                      idQuestao: questao.IdQuestao,
                      idResposta: questao.IdResposta,
                      enunciado: `${questao.Obrigatoria ? '*' : ''}${
                        questao.Ordem
                      }. ${questao.Enunciado}`,
                      descricao: questao.Descricao,
                      permiteComentario: questao.PermiteComentario,
                      comentario: questao.Comentario || '',
                      respondido: questao.Respondido,
                      tipoQuestao: questao.TipoQuestao,
                      ordem: questao.Ordem,
                      obrigatoria: questao.Obrigatoria,
                      respostaTexto: questao.RespostaTexto || '',
                      opcoesRespostaLinha: questao.OpcoesRespostaLinha.map(
                        (opcao: any) => ({
                          id: opcao.Id,
                          descricao: opcao.Descricao,
                          tipoIcone: opcao.TipoIcone,
                          iconeCor: opcao.IconeCor,
                          selecionado: opcao.Selecionado,
                          opcoesRespostaColuna: opcao.OpcoesRespostaColuna.map(
                            (opcao: any) => ({
                              id: opcao.Id,
                              descricao: opcao.Descricao,
                              tipoIcone: opcao.TipoIcone,
                              iconeCor: opcao.IconeCor,
                              selecionado: opcao.Selecionado,
                            }),
                          ),
                        }),
                      ),
                    }),
                  ),
                }),
              ),
            }),
          )
        : [],
    };

    const response: IResultOfAction<IIndividualAnswers> = {
      data: object,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

const getParticipantsById = async (
  idConvite: string,
): Promise<IResultOfAction<IParticipant[]>> => {
  try {
    const {data} = await Api.get(`/relatorios/participantes/${idConvite}`);

    const participantsList: IParticipant[] = data.map((data: any) => ({
      id: data.IdPessoaConvite,
      nome: data.Nome,
      matricula: data.Matricula,
      descricaoCurso: data.Curso,
      papel: data.Papel,
    }));

    const response: IResultOfAction<IParticipant[]> = {
      data: participantsList,
      success: true,
      message: '',
    };

    return response;
  } catch (error) {
    return error as IResultOfAction<any>;
  }
};

export const IndividualAnswersService = {
  getById,
  getParticipantsById,
};
